<template>
  <div>
    <section class="planes">
      <div class="planes__box">
        <h1 class="planes__h1">Planes</h1>
        <p class="planes__p">
          Conoce tu mejor opción con nosotros entenderas cual es el camino
          correcto.
        </p>
      </div>
      <v-tabs background-color="transparent" v-model="tab" centered>
        <v-tab class="projects__tab" href="#landing">Landing Page</v-tab>
        <v-tab class="projects__tab" href="#sites">Sitios Web</v-tab>
        <v-tab class="projects__tab" href="#hosting">Hosting</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item
            style="background: transparent"
            background-color="transparent"
            value="landing"
            :transition="false"
          >
            <plans-landing></plans-landing>
          </v-tab-item>
          <v-tab-item
            style="background: transparent"
            background-color="transparent"
            value="sites"
            :transition="false"
          >
            <plans-sites></plans-sites>
          </v-tab-item>
          <v-tab-item
            style="background: transparent"
            background-color="transparent"
            value="hosting"
            :transition="false"
          >
            <plans-hosting></plans-hosting>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </section>
    <scheduling></scheduling>
  </div>
</template>

<script>
import PlansLanding from "@/components/PlansLanding.vue";
import PlansSites from "@/components/PlansSites.vue";
import PlansHosting from "@/components/PlansHosting.vue";
import Scheduling from "@/components/Scheduling.vue";

export default {
  name: "Plans",
  components: {
    PlansLanding,
    PlansSites,
    PlansHosting,
    Scheduling,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style lang="scss" scoped>
.planes__box {
  margin: auto;
  max-width: 1200px;
  text-align: center;
  padding: 50px 80px 0 80px;
}
.example-3d {
  width: 100%;
  height: 950px;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* .example-3d {
  width: 100%;
  height: 950px;
  padding-top: 50px;
  padding-bottom: 50px;
} */

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    border-radius: 10px;
    border: 4px solid var(--primary);
    font-weight: bold;
    font-size: 2rem;
    background-color: var(--primary);
    background-position: center;
    background-size: cover;
    color: white;
  }

  .swiper-slide--sites {
    width: 300px !important;
    height: 800px !important;
  }

  .swiper-slide--hosting {
    width: 300px;
    height: 500px;
  }
  .card__head--hosting {
    display: grid;
    align-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 20%;
    /* background: var(--white); */
  }
  .card__body--hosting {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    background: var(--white);
    border-radius: 0 0 10px 10px;
  }

  .card__head-h3 {
    font-size: 20px;
  }
  .card__head-last-price {
    color: var(--gray);
    text-decoration: line-through;
    line-height: 1;
    font-size: 25px;
  }
  .card__head-promo-price {
    color: var(--secondary);
    font-weight: 900;
    /* text-decoration: line-through; */
    font-size: 35px;
  }

  .card__body-item {
    height: 40px;
  }
  .swiper-pagination {
    >>> .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: white;
    }
  }
}
.card__body-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: var(--secondary) !important;
  color: black;
  width: 250px;
}
.planes__h1 {
  color: var(--primary-opt);
  font-size: var(--h1);
  /* font-weight: 800; */
  line-height: 0.8;
}

.planes__p {
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}
</style>
