<template>
  <div class="example-3d">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-slide--sites">
        <div class="card__head--sites">
          <h3 class="card__head-h3">Sitio Web Fast</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$1'880.000</span>
          <span class="card__head-promo-price">$1'504.000</span>
        </div>
        <div class="card__body--sites">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon color="orange" v-text="'mdi-star'"></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Incluye Hosting y Dominio
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Certificado SSL </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte y garantía</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >5 Cuentas de correo corporativo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Configuración básica SEO</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Diseño personalizado</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Hasta 5 módulos de navegación</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Configuración de seguimiento Google</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Galeria de fotos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Adaptable a cualquier dispositivo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Formulario de contacto básico</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Carrusel de imágenes</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Seccion de ubicación - Google Maps</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Links a redes sociales</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn class="card__body-btn" elevation="1" dark>
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide--sites">
        <div class="card__head--sites">
          <h3 class="card__head-h3">Sitio Web Standard</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$2´350.000</span>
          <span class="card__head-promo-price">$1’880.000</span>
        </div>
        <div class="card__body--sites">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon color="orange" v-text="'mdi-star'"></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Incluye Hosting y Dominio
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Certificado SSL </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte y garantía</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >5 Cuentas de correo corporativo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Configuración SEO</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Diseño personalizado</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Hasta 5 módulos de navegación</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Configuración de seguimiento Google</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Galeria de fotos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Adaptable a cualquier dispositivo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Formulario de contacto básico</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Banner imágenes dinámicas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Seccion de ubicación - Google Maps</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Links a redes sociales</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn class="card__body-btn" elevation="1" dark>
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide--sites">
        <div class="card__head--sites">
          <h3 class="card__head-h3">Sitio Web Pro</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$2´350.000</span>
          <span class="card__head-promo-price">$1’880.000</span>
        </div>
        <div class="card__body--sites">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon color="orange" v-text="'mdi-star'"></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Incluye Hosting y Dominio
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Certificado SSL </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte y garantía</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >5 Cuentas de correo corporativo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Configuración SEO</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Diseño personalizado</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Hasta 5 módulos de navegación</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Configuración de seguimiento Google</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Galeria de fotos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Adaptable a cualquier dispositivo</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Formulario de contacto básico</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Banner imágenes dinámicas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Seccion de ubicación - Google Maps</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Links a redes sociales</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn class="card__body-btn" elevation="1" dark>
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "PlansSites",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 1,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: -50,
          depth: 180,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.planes__box {
  margin: auto;
  max-width: 1200px;
  text-align: center;
  padding: 50px 80px 0 80px;
}
.example-3d {
  width: 100%;
  height: 950px;
  padding-top: 50px;
  padding-bottom: 50px;
}

/* .example-3d {
  width: 100%;
  height: 950px;
  padding-top: 50px;
  padding-bottom: 50px;
} */

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    border-radius: 10px;
    border: 4px solid var(--primary);
    font-weight: bold;
    font-size: 2rem;
    background-color: var(--primary);
    background-position: center;
    background-size: cover;
    color: white;
    width: 300px;
    height: 800px;
  }

  .card__head--sites {
    display: grid;
    align-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 20%;
  }
  .card__body--sites {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80%;
    background: var(--white);
    border-radius: 0 0 10px 10px;
  }

  .card__head-h3 {
    font-size: 20px;
  }
  .card__head-last-price {
    color: var(--gray);
    text-decoration: line-through;
    line-height: 1;
    font-size: 25px;
  }
  .card__head-promo-price {
    color: var(--secondary);
    font-weight: 900;
    /* text-decoration: line-through; */
    font-size: 35px;
  }

  .card__body-item {
    height: 40px;
  }
  .swiper-pagination {
    >>> .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: white;
    }
  }
}
.card__body-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: var(--secondary) !important;
  color: black;
  width: 250px;
}
.planes__h1 {
  color: var(--primary-opt);
  font-size: 50px;
  /* font-weight: 800; */
  line-height: 0.8;
}

.planes__p {
  margin-top: 20px;
  margin-right: 20px;
  color: var(--black);
  font-size: 20px;
  font-weight: normal;
}
</style>
