<template>
  <div>
    <header-main></header-main>
    <plans></plans>
    <footer-main></footer-main>
  </div>
</template>

<script>
import HeaderMain from "@/components/Header.vue";
import FooterMain from "@/components/Footer.vue";
import Plans from "@/components/Plans.vue";

export default {
  name: "Planes",
  components: {
    HeaderMain,
    Plans,
    FooterMain,
  },
};
</script>
<style scoped></style>
