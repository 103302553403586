<template>
  <div class="example-3d">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="swiper-slide--hosting">
        <div class="card__head--hosting">
          <h3 class="card__head-h3">Partner</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$225.000</span>
          <span class="card__head-promo-price">$180.000</span>
        </div>
        <div class="card__body--hosting">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 Sitio web </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >3 GB de almacenamiento SSD
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Transferencia de datos ilimitados
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >3 cuentas de correo permitidos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Panel de control Cpanel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 Dominio gratis</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Creador de sitios </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Instalador de aplicaciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>SSL gratuito </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>10 subdominios </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 base de datos MySql </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Backup semanal </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte en español </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn
            class="card__body-btn"
            elevation="1"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide--hosting">
        <div class="card__head--hosting">
          <h3 class="card__head-h3">Personal</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$315.000</span>
          <span class="card__head-promo-price">$252.000</span>
        </div>
        <div class="card__body--hosting">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 Sitio web </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >5 GB de almacenamiento SSD
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Transferencia de datos ilimitados
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >5 cuentas de correo permitidos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Panel de control Cpanel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 Dominio gratis</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Creador de sitios </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Instalador de aplicaciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>SSL gratuito </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Subdominios ilimitados </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>2 base de datos MySql </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Backup semanal </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte en español </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn
            class="card__body-btn"
            elevation="1"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide--hosting">
        <div class="card__head--hosting">
          <h3 class="card__head-h3">Emprendedor</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$579.000</span>
          <span class="card__head-promo-price">$463.200</span>
        </div>
        <div class="card__body--hosting">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>2 Sitio web </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >25 GB de almacenamiento SSD
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Transferencia de datos ilimitados
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >10 cuentas de correo permitidos
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Panel de control Cpanel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 Dominio gratis</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Creador de sitios </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Instalador de aplicaciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>SSL gratuito </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Subdominios ilimitados </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>5 base de datos MySql </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Backup semanal </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte en español </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn
            class="card__body-btn"
            elevation="1"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide--hosting">
        <div class="card__head--hosting">
          <h3 class="card__head-h3">Empresarial</h3>

          <v-divider></v-divider>
          <span class="card__head-last-price">$819.000</span>
          <span class="card__head-promo-price">$655.200</span>
        </div>
        <div class="card__body--hosting">
          <v-list dense>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Sitios web ilimitados </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >50 GB de almacenamiento SSD
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Transferencia de datos ilimitados
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Cuentas de correo ilimitadas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Panel de control Cpanel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>1 Dominio gratis</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Creador de sitios </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >Instalador de aplicaciones
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>SSL gratuito </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Subdominios ilimitados </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  >base de datos MySql ilimitadas
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Backup semanal </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="card__body-item">
              <v-list-item-avatar>
                <v-icon
                  color="purple"
                  v-text="'mdi-check-circle-outline'"
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Soporte en español </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
          <v-btn
            class="card__body-btn"
            elevation="1"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <b> Ordenar </b>
          </v-btn>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "PlansHosting",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 1,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 50,
          stretch: -50,
          depth: 180,
          modifier: 1,
          slideShadows: true,
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.example-3d {
  width: 100%;
  height: 950px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    border-radius: 10px;
    border: 4px solid var(--primary);
    font-weight: bold;
    font-size: 2rem;
    background-color: var(--primary);
    background-position: center;
    background-size: cover;
    color: white;
    width: 300px;
    height: 700px;
  }

  .card__head--hosting {
    display: grid;
    align-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 15%;
    /* background: var(--white); */
  }
  .card__body--hosting {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 85%;
    background: var(--white);
    border-radius: 0 0 10px 10px;
  }

  .card__head-h3 {
    font-size: 20px;
  }
  .card__head-last-price {
    color: var(--gray);
    text-decoration: line-through;
    line-height: 1;
    font-size: 25px;
  }
  .card__head-promo-price {
    color: var(--secondary);
    font-weight: 900;
    /* text-decoration: line-through; */
    font-size: 35px;
  }

  .card__body-item {
    height: 40px;
  }
  .swiper-pagination {
    >>> .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: white;
    }
  }
}
.card__body-btn {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background: var(--secondary) !important;
  color: black;
  width: 250px;
}
</style>
